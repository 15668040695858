<template>
  <v-container grid-list-xl>
    <v-layout wrap justify-space-between>
      <v-flex xs12 md12>
        <v-alert :value="alert" type="success">Başarıyla kaldedildi.</v-alert>
      </v-flex>
      <v-flex xs12 md12>
        <v-text-field
          v-model="setting.id"
          label="Digital Ocean Id"
          :counter="max"
          required
        ></v-text-field>
        <v-text-field
          v-model="setting.ip"
          label="Ip"
          :counter="max"
          required
        ></v-text-field>
        <v-text-field
          v-model="setting.machineName"
          label="Machine Name"
          :counter="max"
          required
        ></v-text-field>
        <v-text-field
          v-model="setting.url"
          label="Url"
          :counter="max"
          required
        ></v-text-field>
        <v-text-field
          v-model="setting.version"
          label="Version"
          :counter="max"
          required
        ></v-text-field>

        <v-switch
          v-model="setting.isDeploy"
          :label="`isDeploy (deploy yapılacak sunucular arasında mı)`"
        ></v-switch>
        <v-switch v-model="setting.status" :label="`Status`"></v-switch>
        <template v-if="!setting.status">
          <v-divider></v-divider>
          <h3>Deployment</h3>
          <v-switch v-model="deployStart" :label="`Deploy Start`"></v-switch>
          <template v-if="deployStart">
            <v-text-field
              v-model="messageCode"
              label="messageCode"
              :counter="max"
              required
            ></v-text-field>

            <v-combobox
              v-model="deploymentType"
              :items="items"
              chips
            ></v-combobox>
            <v-combobox
              v-model="transferServer"
              :items="this.$store.state.servers.all"
              item-value="id"
              item-text="url"
              hide-details
              label="Transfer Server"
            ></v-combobox>
            <v-subheader>Deploy Scheduler</v-subheader>
            <v-slider
              v-if="deploymentType !== 'Quicky'"
              v-model="deployScheduleTime"
              thumb-label="always"
              label="Deploy Scheduler Time"
            ></v-slider>
          </template>
        </template>
      </v-flex>
      <v-btn color="success" @click="save">Kaydet</v-btn>
    </v-layout>
  </v-container>
</template>
<script>
import router from "@/router";
import Api from "@/store/modules/baseApi";

export default {
  data: () => ({
    setting: {},
    servers: [],
    alert: false,
    max: 120,
    messageCode: "MESSAGE01",
    deployScheduleTime: 5,
    deployStart: false,
    deploymentType: "Schedule",
    items: ["Quicky", "Schedule"],
    transferServer: null,
  }),

  methods: {
    async save() {
      await this.$store.dispatch("servers/update", this.setting);
      if (this.deployStart && this.transferServer) {
        // eslint-disable-next-line no-unused-vars
        const aa = {
          TransferServer: this.transferServer.url,
          // Message: "Sunucu 5 Dk içinde restart olacaktır",
          MessageCode: this.messageCode,
          DeployStart: this.deployStart,
          DeployScheduleTime: this.deployScheduleTime * 60000,
          DeploymentType: this.deploymentType.url,
        };
        let res = await Api.post(`${this.setting.url}/api/Deployment`, aa);
        // eslint-disable-next-line no-console
        console.log(res);
      }
      router.push("/config/servers");
    },
  },
  validateField() {
    this.$refs.form.validate();
  },
  computed: {},
  async created() {
    if (this.$route.params.id)
      await this.$store.dispatch("servers/get", this.$route.params.id);
    this.setting = this.$store.state.servers.detail;
  },
};
</script>
